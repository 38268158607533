import React from 'react';
import { Helmet } from 'react-helmet';
import { GetStarted } from '../components/get-started';
import { LandingPageLayout } from '../components/layout';
import { colors } from '../modules/colors';
export const GetStartedPage = () => (
  <main style={{ backgroundColor: colors.AQUA }}>
    <LandingPageLayout>
      <Helmet>
        <style>{`
          nav {
            background-color: ${colors.AQUA}
          }
        `}</style>
      </Helmet>
      <GetStarted />
    </LandingPageLayout>
  </main>
);

export default GetStartedPage;
