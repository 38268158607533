import { colors } from '@rategravity/marketing-components';
import { buildMediaQueries } from '@rategravity/marketing-components';
import { createOwnUpComponent, OwnUpText } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled from 'styled-components';
import { GetStartedReviewProps, getStartedReviews } from '../../../data/content/get-started';
import QuoteSVG from '../../../images/icons/quote.svg';
import { BoldTextStyle, HeaderMixin, QuoteStyle } from '../../typography/icon-landing';

const ReviewsWrapper = createOwnUpComponent('div', {
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  backgroundColor: colors.WHITE,
  paddingBottom: '50px',
  paddingTop: '65px',
  width: '100%',
  variants: {
    mediumAndDown: {
      paddingBottom: '30px'
    }
  }
});

const GroupedReviewsWrapper = createOwnUpComponent('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'row',
  variants: {
    mediumAndDown: {
      flexDirection: 'column',
      alignItems: 'center'
    },
    mediumAndUp: {
      marginBottom: '20px'
    }
  }
});

const ReviewPanelWrapper = createOwnUpComponent('div', {
  width: '310px',
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  padding: '0px 10px',
  '& > *:not(:first-child, :last-child)': {
    marginBottom: '20px'
  },
  variants: {
    medium: {
      width: '60%',
      padding: '0px 5px 50px'
    },
    smallAndDown: {
      width: '80%',
      padding: '0px 5px 50px'
    }
  }
});

const ReviewsHeaderWrapper = createOwnUpComponent('div', {
  margin: '0 auto 100px',
  width: '575px',
  textAlign: 'center',
  variants: {
    medium: {
      width: '60%',
      margin: '0 auto 50px'
    },
    smallAndDown: {
      width: '80%',
      margin: '0 auto 50px'
    }
  }
});

export const Quote = createOwnUpComponent('div', {
  ...QuoteStyle,
  lineHeight: '32px',
  textAlign: 'center',
  ':before': {
    content: '"“"'
  },
  ':after': {
    content: '"”"'
  },
  variants: {
    xsmall: {
      maxWidth: '90%'
    }
  }
});

export const Attribution = createOwnUpComponent('div', BoldTextStyle);

const GroupedReviewsHeadline = styled.h2`
  ${HeaderMixin};
  font-size: 40px;
  line-height: 52px;
  ${buildMediaQueries('smallAndDown', {
    fontSize: '32px',
    lineHeight: '40px'
  })}
`;

const GroupedReviews = ({ reviewsData }: { reviewsData: GetStartedReviewProps[] }) => {
  const reviews = reviewsData.map(({ quote, attribution }, i) => (
    <ReviewPanelWrapper key={i}>
      <img
        src={QuoteSVG}
        alt={''} // skip on screen readers
        style={{ margin: '0 0 15px', display: 'block', height: '30px' }}
      />
      <Quote>{quote}</Quote>
      <Attribution>{attribution}</Attribution>
    </ReviewPanelWrapper>
  ));

  return <GroupedReviewsWrapper>{reviews}</GroupedReviewsWrapper>;
};

const ReviewsText = () => (
  <ReviewsHeaderWrapper>
    <GroupedReviewsHeadline>Don&apos;t just take our word for it</GroupedReviewsHeadline>
    <OwnUpText>
      Own Up has been helping borrowers get a great deal on their mortgage for years. Hear from
      people like you.
    </OwnUpText>
  </ReviewsHeaderWrapper>
);
export const GetStartedReviews = () => (
  <ReviewsWrapper>
    <ReviewsText />
    <GroupedReviews reviewsData={getStartedReviews} />
  </ReviewsWrapper>
);
